import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./screens/webinar-replay/webinar-video"
import BodyLanding from "./screens/webinar-replay/2022-06-29-sweeney/body-landing"

const SweeneyDox = () => {
		return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Revolutionizing STI Testing for You and Your Patients"
				videoDisplayTitle="Revolutionizing STI Testing for You and Your Patients"
				vimeoLink="https://player.vimeo.com/video/727460180?h=1860b5c010"
				placeholderImage="2022-06-29-Sweeney-webinar-replay.jpg"
				publicView
			/>
			<BodyLanding pageName="download" publicView />
		</React.Fragment>
	)
}

export default SweeneyDox

export const Head = () => (
  <Seo
		title="Webinar Replay: Revolutionizing STI Testing for You and Your Patients"
        description="Presenter Dr. Denise Sweeney talks about revolutionizing STI testing for you and your patients."
		image="/meta/webinar-sweeney-2022-06-29.jpg"
  />
)
